import React from "react"
import JPEG1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1.jpg"
import JPEG1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@2x.jpg"
import JPEG1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@mob.jpg"
import JPEG1Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@mob2x.jpg"
import JPEG1Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@tab.jpg"
import JPEG1Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@tab2x.jpg"
import WEBP1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1.webp"
import WEBP1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@2x.webp"
import WEBP1Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@tab.webp"
import WEBP1Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@tab2x.webp"
import WEBP1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@mob.webp"
import WEBP1Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-1@mob2x.webp"

import JPEG2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2.jpg"
import JPEG2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@2x.jpg"
import JPEG2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@mob.jpg"
import JPEG2Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@mob2x.jpg"
import JPEG2Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@tab.jpg"
import JPEG2Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@tab2x.jpg"
import WEBP2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2.webp"
import WEBP2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@2x.webp"
import WEBP2Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@tab.webp"
import WEBP2Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@tab2x.webp"
import WEBP2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@mob.webp"
import WEBP2Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-2@mob2x.webp"

import JPEG3 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3.jpg"
import JPEG3_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@2x.jpg"
import JPEG3Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@mob.jpg"
import JPEG3Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@mob2x.jpg"
import JPEG3Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@tab.jpg"
import JPEG3Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@tab2x.jpg"
import WEBP3 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3.webp"
import WEBP3_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@2x.webp"
import WEBP3Tab from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@tab.webp"
import WEBP3Tab_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@tab2x.webp"
import WEBP3Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@mob.webp"
import WEBP3Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/SellSection/webApp-sell-3@mob2x.webp"

const PG_JASB_SELL_SECTION_CONTENT = [
  {
    screenshot: {
      JPEG: JPEG1,
      JPEG2x: JPEG1_2x,
      JPEGTab: JPEG1Tab,
      JPEGTab2x: JPEG1Tab_2x,
      JPEGMob: JPEG1Mob,
      JPEGMob2x: JPEG1Mob_2x,
      WEBP: WEBP1,
      WEBP2x: WEBP1_2x,
      WEBPTab: WEBP1Tab,
      WEBPTab2x: WEBP1Tab_2x,
      WEBPMob: WEBP1Mob,
      WEBPMob2x: WEBP1Mob_2x,
    },
    title:
      "リバースASINルックアップを使用すると、ASIN番号を入力するだけで、商品がランク付けされているすべてのキーワードを取得できます。",
    list: [
      "競争者の商品がランク付けされているすべてのキーワードをすばやく確認できます。リストをコピーして、成功しているセラーにすばやく追いつきます。",
      "人々があなたのような商品を説明するために使用するキーワードを取り、購入者がアマゾン検索で簡単に見つけられるリストを作成します",
      "リバースASINルックアップでは、各キーワードが検索された頻度も表示されるため、先月このアイテムを検索した人の数を知ることができます。",
    ],
    text: "",
  },
  {
    screenshot: {
      JPEG: JPEG2,
      JPEG2x: JPEG2_2x,
      JPEGTab: JPEG2Tab,
      JPEGTab2x: JPEG2Tab_2x,
      JPEGMob: JPEG2Mob,
      JPEGMob2x: JPEG2Mob_2x,
      WEBP: WEBP2,
      WEBP2x: WEBP2_2x,
      WEBPTab: WEBP2Tab,
      WEBPTab2x: WEBP2Tab_2x,
      WEBPMob: WEBP2Mob,
      WEBPMob2x: WEBP2Mob_2x,
    },
    title:
      "アマゾンキーワード検索で商品に関連する何千ものキーワードを入手してください",
    list: [
      "人々があなたの商品を検索するために使用できる可能性のあるすべての単語やフレーズを追加することにより、あなたの製品を見つける可能性を最大化します",
      "PPC広告の新しいキーワードを試して、1ドルで最も多くの購入者を獲得できるキーワードを見つけてください",
    ],
    text: "",
  },
  {
    screenshot: {
      JPEG: JPEG3,
      JPEG2x: JPEG3_2x,
      JPEGTab: JPEG3Tab,
      JPEGTab2x: JPEG3Tab_2x,
      JPEGMob: JPEG3Mob,
      JPEGMob2x: JPEG3Mob_2x,
      WEBP: WEBP3,
      WEBP2x: WEBP3_2x,
      WEBPTab: WEBP3Tab,
      WEBPTab2x: WEBP3Tab_2x,
      WEBPMob: WEBP3Mob,
      WEBPMob2x: WEBP3Mob_2x,
    },
    title:
      "キーワードトラッカーを使用して、広告されたキーワードのランク付けを正確に監視することで、広告予算からさらに多くの売り上げを得ることができます。",
    list: [
      "有料キーワードがオーガニックランキングを最大限に高めた時期を把握し、キーワードへの支出をやめるときです。",
      "お金を浪費するのに、商品を上位にランク付けせず、無駄なキーワードを見つけて除外します。",
      "競争者の活動を確認し、重要なキーワードで競争者があなたを上回ろうとした場合に対応します",
    ],
    text: "ASINを使用してキーワードトラッカーに商品を追加し、追跡するキーワードを選択して、商品のランクを確認するだけです。",
  },
]

export default PG_JASB_SELL_SECTION_CONTENT
