import React from "react"
import PNGHomeIntro from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro.png"
import PNGHomeIntro_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro@2x.png"
import PNGHomeIntro991 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_991.png"
import PNGHomeIntro991_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_991@2x.png"
import PNGHomeIntro768 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_768.png"
import PNGHomeIntro768_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_768@2x.png"
import PNGHomeIntroMob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_mob.png"
import PNGHomeIntroMob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_mob@2x.png"

import WEBPHomeIntro from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro.webp"
import WEBPHomeIntro_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro@2x.webp"
import WEBPHomeIntro991 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_991.webp"
import WEBPHomeIntro991_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_991@2x.webp"
import WEBPHomeIntro768 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_768.webp"
import WEBPHomeIntro768_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_768@2x.webp"
import WEBPHomeIntroMob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_mob.webp"
import WEBPHomeIntroMob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/HomeIntro/webApp-intro_mob@2x.webp"

import PointIcon from "../../../../assets/images/vector/components/common/group-copy.svg"

const PG_JASB_HOME_INTRO_CONTENT = {
  pictureScreen: {
    PNGHomeIntro,
    PNGHomeIntro_2x,
    PNGHomeIntro991,
    PNGHomeIntro991_2x,
    PNGHomeIntro768,
    PNGHomeIntro768_2x,
    PNGHomeIntroMob,
    PNGHomeIntroMob_2x,
    WEBPHomeIntro,
    WEBPHomeIntro_2x,
    WEBPHomeIntro991,
    WEBPHomeIntro991_2x,
    WEBPHomeIntro768,
    WEBPHomeIntro768_2x,
    WEBPHomeIntroMob,
    WEBPHomeIntroMob_2x,
  },
  PointIcon,
}
export default PG_JASB_HOME_INTRO_CONTENT
