import JPEG1 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic.jpg"
import JPEG1_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@2x.jpg"
import JPEG1Mob from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@mob.jpg"
import JPEG1Mob_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@mob2x.jpg"
import WEBP1 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic.webp"
import WEBP1_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@2x.webp"
import WEBP1Mob from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@mob.webp"
import WEBP1Mob_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic@mob2x.webp"

import JPEG2 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic1.jpg"
import JPEG2_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic1@2x.jpg"
import WEBP2 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic1.webp"
import WEBP2_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic1@2x.webp"

import JPEG3 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic2.jpg"
import JPEG3_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic2@2x.jpg"
import WEBP3 from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic2.webp"
import WEBP3_2x from "../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-formPic2@2x.webp"

const PG_JASB_CONTENT = {
  ctaSectionScreenshot: {
    JPEG: JPEG1,
    JPEG2x: JPEG1_2x,
    JPEGMob: JPEG1Mob,
    JPEGMob2x: JPEG1Mob_2x,
    WEBP: WEBP1,
    WEBP2x: WEBP1_2x,
    WEBPMob: WEBP1Mob,
    WEBPMob2x: WEBP1Mob_2x,
  },
  form2: {
    JPEG: JPEG2,
    JPEG2x: JPEG2_2x,
    WEBP: WEBP2,
    WEBP2x: WEBP2_2x,
    title:
      "ステップバイステップコースとマスタークラスを今すぐ無料で確認してください！",
    buttonTextFirstState: "AMZSCOUTを無料でお試しください！",
    buttonTextSecondState: "AMZSCOUTを無料でお試しください！",
  },
  form3: {
    JPEG: JPEG3,
    JPEG2x: JPEG3_2x,
    WEBP: WEBP3,
    WEBP2x: WEBP3_2x,
    title:
      "商品データベースとPRO拡張機能の無料トライアルで、今すぐ収益性の高い商品を見つけてください！",
    buttonTextFirstState: "AMZSCOUTを無料でお試しください！",
    buttonTextSecondState: "AMZSCOUTを無料でお試しください！",
  },

  postData: [
    {
      title: "AMZScout is a must have...",
      description: () =>
        "AMZScout is a must have if you want to be successful in your Amazon business. AMZScout has all of the tools you need in order to find and source the right products.",
    },
    {
      title: "I absolutely love the tool...",
      description: () =>
        "I absolutely love the tool. It provides great insights into the trends and niches. I have been able to find a lot of good winners. They have great support as well. Thanks.",
    },
    {
      title: "One strength of AMZScout is...",
      description: () =>
        "One strength of AMZScout is that you can easily see how much your competitors sell and at what prices.",
    },
  ],
}

export default PG_JASB_CONTENT
