import React from "react";
import PG_JASB_SELL_SECTION_CONTENT from "./index.content";
import SellSectionScreenshot from "./SellSectionScreenshot";
import "./index.scss";

const SellSection = () => (
  <section className="PgJASB-SellSection">
    <div className="container">
      <h2 className="PgJASB-SellSection__title">製品を販売する</h2>
      <div className="PgJASB-SellSection__subtitle">
        購入者にとって見つけやすいリストと費用対効果の高いクリック課金広告付き
      </div>
      {PG_JASB_SELL_SECTION_CONTENT.map(
        ({ screenshot, title, list, text }, index) => (
          <div
            key={title}
            className={`PgJASB-SellSection-row PgJASB-SellSection-row-${
              index + 1
            }`}
          >
            <SellSectionScreenshot
              screenshot={screenshot}
              classes="PgJASB-SellSection__img"
              index={index}
            />
            <div className="PgJASB-SellSection-info">
              <div className="PgJASB-SellSection-info__title">{title}</div>
              {list.map((item) => (
                <div key={item} className="PgJASB-SellSection-info__item">
                  {item}
                </div>
              ))}
              {text ? (
                <div className="PgJASB-SellSection-info__text">{text}</div>
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  </section>
);

export default SellSection;
