import React from "react";
import PG_JASB_TOOLS_CONTENT from "./index.content";
import SimpleScreenshot from "../../../common/UI/SimpleScreenshot";
import "./index.scss";

const Tools = () => (
  <section className="PgJASB-Tools">
    <div className="container">
      <h1 className="PgJASB-Tools__title">
        アマゾンセラーのための12最高ツール
      </h1>
      <div className="PgJASB-Tools__subtitle">
        AMZScoutは、Amazonで販売するために必要な専門知識とツールを提供する
      </div>
      {PG_JASB_TOOLS_CONTENT.map(
        ({ screenshot, showShadow, title, list, additionalPic }) => (
          <div key={title} className="PgJASB-Tools-row">
            <div className="PgJASB-Tools-info">
              <div className="PgJASB-Tools-info__title">{title}</div>
              {list.map(({ firstText, secondText }) => (
                <div key={firstText} className="PgJASB-Tools-info__item">
                  <div>{firstText}</div>
                  <div>{secondText}</div>
                </div>
              ))}
            </div>
            <div className="PgJASB-Tools__img">
              <SimpleScreenshot
                classes={showShadow && "PgJASB-Tools__shadow"}
                screenshot={screenshot}
                from={768}
                to={767}
              />
              {!!additionalPic && (
                <SimpleScreenshot
                  classes={showShadow && "PgJASB-Tools__shadow"}
                  screenshot={additionalPic}
                  from={768}
                  to={767}
                />
              )}
            </div>
          </div>
        )
      )}
    </div>
  </section>
);

export default Tools;
