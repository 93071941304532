import React, { useState, useEffect, useRef, useContext } from "react";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import PG_JASB_CONTENT from "../../components/jp/amazon-sellers-bundle/index.content";
import { PAGE_OPTIONS, PAGES } from "../../helpers/constants";
import { getTrueClasses } from "../../helpers";
import Layout from "../../layouts/LayoutJp";
import Seo from "../../components/Seo";
import HomeIntro from "../../components/jp/amazon-sellers-bundle/HomeIntro";
import JpChromeRating from "../../components/common/JpChromeRating";
import PartnerBrand from "../../components/common/PartnerBrand";
import JpRatingReview from "../../components/common/JpRatingReview";
import Tools from "../../components/jp/amazon-sellers-bundle/Tools";
import LastSection from "../../components/jp/amazon-sellers-bundle/LastSection";
import KnowHow from "../../components/jp/amazon-sellers-bundle/KnowHow";
import FindSection from "../../components/jp/amazon-sellers-bundle/FindSection";
import CtaSection from "../../components/jp/amazon-sellers-bundle/CtaSection";
import SellSection from "../../components/jp/amazon-sellers-bundle/SellSection";
import FormWithPicture from "../../components/jp/amazon-sellers-bundle/FormWithPicture";
import CustomizableContainer from "../../containers/Paywalls/Customizable/CustomizableContainer";
import JpStandardFormBlockContainer from "../../containers/common/forms/JpStandardFormBlockContainer";
import "../../components/jp/amazon-sellers-bundle/index.scss";

const PgJASB = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const tariffs = useRef(null);
  const formRef = useRef(null);

  const { ctaSectionScreenshot, form2, form3, postData } = PG_JASB_CONTENT;

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  const handleClickOneMiddleForm = () => {
    if (isAuth) {
      window.open(
        PAGE_OPTIONS[PAGES.JP_AMAZON_SELLERS_BUNDLE].REDIRECTED_URL,
        "_blank"
      );
    } else {
      formRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Layout
      urlYoutubeModal={urlYoutubeModal}
      tariffsElement={tariffs}
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      customClass="PgJASB"
    >
      <Seo
        title={`${process.env.YEAR}年のアマゾンセラーツールトップ12`}
        description="FBAとFBMセラー向けの必須のアマゾンセラーツール：1. ステップバイステップコース 2. 商品リサーチツールキット 3. キーワードツール（キーワード検索、ASINルックアップ)"
        page="jp/amazon-sellers-bundle"
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <JpChromeRating />
      <PartnerBrand />
      <JpRatingReview posts={postData} />
      <Tools />
      <CtaSection
        handleClick={handleClickOneMiddleForm}
        screenshot={ctaSectionScreenshot}
      />
      <CustomizableContainer
        language="ja"
        pricingRef={tariffs}
        customClass="PgJASB-pricing"
        subTitlePro
      />
      <KnowHow />
      <FormWithPicture
        content={form2}
        customClass="PgJASB-FormWithPicture-one"
        formRef={formRef}
        page={PAGES.JP_AMAZON_SELLERS_BUNDLE}
      />
      <FindSection />
      <FormWithPicture
        content={form3}
        customClass="PgJASB-FormWithPicture-two"
        page={PAGES.JP_AMAZON_SELLERS_BUNDLE}
      />
      <SellSection />
      <JpStandardFormBlockContainer
        buttonTextFirstState="AMZSCOUTを無料でお試しください！"
        buttonTextSecondState="AMZSCOUTを無料でお試しください！"
        page={PAGES.JP_AMAZON_SELLERS_BUNDLE}
        customClass={getTrueClasses(
          "PgJASB-bottomForm",
          "PgJASB-bottomForm_mod",
          isAuth && "PgJASB-bottomForm-secondState"
        )}
        title="メ ルアドレスを入力して、無料トライアルを開始 していただけます。"
      />
      <LastSection />
    </Layout>
  );
};

export default PgJASB;
