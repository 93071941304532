import React from "react"
import JPEG1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1.jpg"
import JPEG1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@2x.jpg"
import JPEG1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@mob.jpg"
import JPEG1Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@mob2x.jpg"
import WEBP1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1.webp"
import WEBP1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@2x.webp"
import WEBP1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@mob.webp"
import WEBP1Mob_2x
  from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-1@mob2x.webp"

import JPEG2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2.jpg"
import JPEG2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@2x.jpg"
import JPEG2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob.jpg"
import JPEG2Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob2x.jpg"
import WEBP2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2.webp"
import WEBP2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@2x.webp"
import WEBP2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob.webp"
import WEBP2Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob2x.webp"

import JPEG3 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-3.jpg"
import JPEG3_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-3@2x.jpg"
import WEBP3 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-3.webp"
import WEBP3_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-3@2x.webp"
import JPEG4 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-4.jpg"
import JPEG4_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-4@2x.jpg"
import WEBP4 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-4.webp"
import WEBP4_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/FindSection/webApp-find-4@2x.webp"

const PG_JASB_FIND_SECTION_CONTENT = [
  {
    screenshot: {
      JPEG: JPEG3,
      JPEG2x: JPEG3_2x,
      JPEGMob: JPEG3,
      JPEGMob2x: JPEG3_2x,
      WEBP: WEBP3,
      WEBP2x: WEBP3_2x,
      WEBPMob: WEBP3,
      WEBPMob2x: WEBP3_2x,
    },
    additionalPic: {
      JPEG: JPEG4,
      JPEG2x: JPEG4_2x,
      WEBP: WEBP4,
      WEBP2x: WEBP4_2x,
    },
    title: "月次トレンドレポートとすぐに販売できる商品を確認する",
    list: [
      "レポートの素晴らしい機会からAmazonで販売する商品を選んでください。 最新のレポートを読み、提案された商品とニッチをチェックし、販売統計を確認して、1つを選択するだけです。 簡単そうです。",
      "非常に具体的な商品が必要で、レポートで必要なものが見つからなかった場合は、商品データベースを使用してください。",
    ],
  },
  {
    screenshot: {
      JPEG: JPEG1,
      JPEG2x: JPEG1_2x,
      JPEGMob: JPEG1Mob,
      JPEGMob2x: JPEG1Mob_2x,
      WEBP: WEBP1,
      WEBP2x: WEBP1_2x,
      WEBPMob: WEBP1Mob,
      WEBPMob2x: WEBP1Mob_2x,
    },
    title: "商品データベース使用:",
    list: [
      "5億個の製品の中で、販売マージン、重量、その他15個のフィルターの点で好みに合った製品のリストを取得します",
      "競争が激しい商品を簡単に除外します",
      "調査する価値のある可能性がある新商品についてのアイデアが不足することはありません",
    ],
    boldText: () => (
      <>
        商品データベースは、自社ブランド、アービトラージ、ドロップシッピング、卸売製品調査にとって非常に重要です。
      </>
    ),
    text: () => (
      <>
        商品を見つけたら、<b>商品トラッカー</b>
        で商品のパフォーマンスを追跡して、毎日の実際の売上を確認し、商品に投資する前に商品の需要をライブで観察します。これは、販売を開始するときに競争者を注意深く監視するのに最適なアマゾンFBAツールです。
      </>
    ),
  },
  {
    screenshot: {
      JPEG: JPEG2,
      JPEG2x: JPEG2_2x,
      JPEGMob: JPEG2Mob,
      JPEGMob2x: JPEG2Mob_2x,
      WEBP: WEBP2,
      WEBP2x: WEBP2_2x,
      WEBPMob: WEBP2Mob,
      WEBPMob2x: WEBP2Mob_2x,
    },
    title:
      "販売する価値があると思われる商品を見つけたら、以下の通りでPRO拡張機能を使用してください",
    list: [
      "商品のポテンシャルと競争レベルの見積もりをすばやく取得します。PRO拡張機能は、即時評価のために1から10までの飽和スコアと製品スコアを提供します。",
      "安全な投資であることを確認するために、商品の販売統計と販売履歴を詳しく調べて、販売されない大量の商品にとらわれないようにします。",
      "アマゾンの製品ページにアクセスすると、毎月の収益、競争力、価格履歴、関連性の高い統計を即座に確認して、販売するかどうかの最終決定を下すことができます。",
    ],
    boldText: () => (
      <>
        PRO拡張機能には50万人以上のユーザーがいて、
        <span>何百万もの収益性の高い商品</span>を見つけるのに役立っています。
      </>
    ),
  },
]

export default PG_JASB_FIND_SECTION_CONTENT
