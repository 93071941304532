import React from "react";
import ScreenshotWithWrapper from "../../../common/UI/ScreenshotWithWrapper";
import "./index.scss";

const CtaSection = ({ handleClick, screenshot }) => {
  return (
    <section className="PgJASB-CtaSection">
      <div className="container">
        <div className="PgJASB-CtaSection-row">
          <div className="PgJASB-CtaSection__pretitle">試してみる</div>
          <div className="PgJASB-CtaSection__title">
            アマゾンツールをすべて試してみてください <br />
            今すぐ無料で！
          </div>
          <div className="PgJASB-CtaSection__btn" onClick={handleClick}>
            AMZSCOUTを無料でお試しください！
          </div>
          <ScreenshotWithWrapper
            classes="PgJASB-CtaSection__img"
            screenshot={screenshot}
            from={768}
            to={767}
          />
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
