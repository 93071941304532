import React from "react";
import PG_JASB_FIND_SECTION_CONTENT from "./index.content";
import "./index.scss";
import SimpleScreenshot from "../../../common/UI/SimpleScreenshot";

const FindSection = () => {
  return (
    <section className="PgJASB-FindSection">
      <div className="container">
        <h2 className="PgJASB-FindSection__title">適切な商品を見つける</h2>
        {PG_JASB_FIND_SECTION_CONTENT.map(
          (
            { screenshot, additionalPic, list, title, text, boldText },
            index
          ) => (
            <div
              key={title}
              className={`PgJASB-FindSection-row PgJASB-FindSection-row-${
                index + 1
              }`}
            >
              <div className="PgJASB-FindSection-info">
                <div className="PgJASB-FindSection-info__title">{title}</div>
                {list.map((item) => (
                  <div key={item} className="PgJASB-FindSection-info__item">
                    {item}
                  </div>
                ))}
                {boldText?.() ? (
                  <div className="PgJASB-FindSection-info__text">
                    <b>{boldText?.()}</b>
                  </div>
                ) : null}
                {text?.() ? (
                  <div className="PgJASB-FindSection-info__text">{text()}</div>
                ) : null}
              </div>
              <div className="PgJASB-FindSection__img">
                <SimpleScreenshot screenshot={screenshot} from={768} to={767} />
                {additionalPic ? (
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={`${additionalPic.WEBP} 1x, ${additionalPic.WEBP2x} 2x`}
                    />
                    <img
                      srcSet={`${additionalPic.JPEG} 1x, ${additionalPic.JPEG2x} 2x`}
                      alt="img"
                      loading="lazy"
                    />
                  </picture>
                ) : null}
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default FindSection;
