import React from "react";
import PropTypes from "prop-types";

const ScreenshotWithWrapper = ({ classes, screenshot, from, to }) => {
  const { JPEG, JPEG2x, JPEGMob, JPEGMob2x, WEBP, WEBP2x, WEBPMob, WEBPMob2x } =
    screenshot;

  return (
    <div className={classes}>
      <picture>
        <source
          type="image/webp"
          media={`(min-width: ${from}px)`}
          srcSet={`${WEBP} 1x, ${WEBP2x} 2x`}
        />
        <source
          media={`(min-width: ${from}px)`}
          srcSet={`${JPEG} 1x, ${JPEG2x} 2x`}
        />
        <source
          type="image/webp"
          media={`(max-width: ${to}px)`}
          srcSet={`${WEBPMob} 1x, ${WEBPMob2x} 2x`}
        />
        <source
          media={`(max-width: ${to}px)`}
          srcSet={`${JPEGMob} 1x, ${JPEGMob2x} 2x`}
        />
        <img src={JPEG} alt="img" loading="lazy" />
      </picture>
    </div>
  );
};
ScreenshotWithWrapper.propTypes = {
  classes: PropTypes.string,
  screenshot: PropTypes.object,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ScreenshotWithWrapper;
