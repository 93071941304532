import JPEG1 from "../../../../assets/images/raster/components/Tools/webApp-tools-1.jpg"
import JPEG1_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-1@2x.jpg"
import JPEG1Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-1@mob.jpg"
import JPEG1Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-1@mob2x.jpg"
import WEBP1 from "../../../../assets/images/raster/components/Tools/webApp-tools-1.webp"
import WEBP1_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-1@2x.webp"
import WEBP1Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-1@mob.webp"
import WEBP1Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-1@mob2x.webp"

import JPEG2 from "../../../../assets/images/raster/components/Tools/webApp-tools-2.jpg"
import JPEG2_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-2@2x.jpg"
import JPEG2Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-2@mob.jpg"
import JPEG2Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-2@mob2x.jpg"
import WEBP2 from "../../../../assets/images/raster/components/Tools/webApp-tools-2.webp"
import WEBP2_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-2@2x.webp"
import WEBP2Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-2@mob.webp"
import WEBP2Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-2@mob2x.webp"

import JPEG3 from "../../../../assets/images/raster/components/Tools/webApp-tools-3.jpg"
import JPEG3_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-3@2x.jpg"
import JPEG3Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-3@mob.jpg"
import JPEG3Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-3@mob2x.jpg"
import WEBP3 from "../../../../assets/images/raster/components/Tools/webApp-tools-3.webp"
import WEBP3_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-3@2x.webp"
import WEBP3Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-3@mob.webp"
import WEBP3Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-3@mob2x.webp"

import JPEG4 from "../../../../assets/images/raster/components/Tools/webApp-tools-4.jpg"
import JPEG4_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-4@2x.jpg"
import JPEG4Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-4@mob.jpg"
import JPEG4Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-4@mob2x.jpg"
import WEBP4 from "../../../../assets/images/raster/components/Tools/webApp-tools-4.webp"
import WEBP4_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-4@2x.webp"
import WEBP4Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-4@mob.webp"
import WEBP4Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-4@mob2x.webp"

import JPEG5 from "../../../../assets/images/raster/components/Tools/webApp-tools-5.jpg"
import JPEG5_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-5@2x.jpg"
import JPEG5Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-5@mob.jpg"
import JPEG5Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-5@mob2x.jpg"
import WEBP5 from "../../../../assets/images/raster/components/Tools/webApp-tools-5.webp"
import WEBP5_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-5@2x.webp"
import WEBP5Mob from "../../../../assets/images/raster/components/Tools/webApp-tools-5@mob.webp"
import WEBP5Mob_2x from "../../../../assets/images/raster/components/Tools/webApp-tools-5@mob2x.webp"

import JPEG6 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2.jpg"
import JPEG6_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@2x.jpg"
import JPEG6Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob.jpg"
import JPEG6Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob2x.jpg"
import WEBP6 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2.webp"
import WEBP6_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@2x.webp"
import WEBP6Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob.webp"
import WEBP6Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/common/webApp-find-2@mob2x.webp"

const PG_JASB_TOOLS_CONTENT = [
  {
    screenshot: {
      JPEG: JPEG4,
      JPEG2x: JPEG4_2x,
      JPEGMob: JPEG4Mob,
      JPEGMob2x: JPEG4Mob_2x,
      WEBP: WEBP4,
      WEBP2x: WEBP4_2x,
      WEBPMob: WEBP4Mob,
      WEBPMob2x: WEBP4Mob_2x,
    },
    additionalPic: {
      JPEG: JPEG5,
      JPEG2x: JPEG5_2x,
      JPEGMob: JPEG5Mob,
      JPEGMob2x: JPEG5Mob_2x,
      WEBP: WEBP5,
      WEBP2x: WEBP5_2x,
      WEBPMob: WEBP5Mob,
      WEBPMob2x: WEBP5Mob_2x,
    },
    showShadow: true,
    title: "1. アマゾンで発売する100万分の1の可能性があるベストセラー",
    list: [
      {
        firstText: "年間1,000以上のアマゾンインサイト - 毎週19の人気商品",
        secondText:
          "ベストセラーになる可能性があるにもかかわらず、競争が少ない19の需要の高い商品を受け取ります。 こちらの商品は、毎週6億以上の商品からAIによって最適な機会として選択されています。",
      },
      {
        firstText: "月次アマゾン商品トレンドレポート",
        secondText:
          "毎月の商品トレンドを含むレポートを受け取り、アマゾンのどのカテゴリがより収益性が高くなり、どのカテゴリを避けるべきかを把握します。 収益性の高いニッチ、Amazonのニュースとルールの更新を確認し、利益を最大化するためのヒントを読んでください！",
      },
    ],
  },
  {
    screenshot: {
      JPEG: JPEG1,
      JPEG2x: JPEG1_2x,
      JPEGMob: JPEG1Mob,
      JPEGMob2x: JPEG1Mob_2x,
      WEBP: WEBP1,
      WEBP2x: WEBP1_2x,
      WEBPMob: WEBP1Mob,
      WEBPMob2x: WEBP1Mob_2x,
    },
    additionalPic: {
      JPEG: JPEG6,
      JPEG2x: JPEG6_2x,
      JPEGMob: JPEG6Mob,
      JPEGMob2x: JPEG6Mob_2x,
      WEBP: WEBP6,
      WEBP2x: WEBP6_2x,
      WEBPMob: WEBP6Mob,
      WEBPMob2x: WEBP6Mob_2x,
    },
    showShadow: false,
    title: "2. 月額9,000ドル以上のGEMSを見つけるための製品調査ツール",
    list: [
      {
        firstText: "商品データベース",
        secondText:
          "数回クリックするだけで何百万もの商品をフィルタリングして、Amazonで販売する収益性の高い商品を見つけましょう！",
      },
      {
        firstText: "PRO拡張機能",
        secondText:
          "堅実な販売統計に基づいて商品のポテンシャルと競争レベルをすばやく見積もることができる、有名な製品調査ツールです。 また、投資前の徹底的な製品確認のための販売履歴や他のデータも提供します。",
      },
      {
        firstText: "商品トラッカー",
        secondText:
          "商品の可能性を評価し、競争者を監視するために、毎日の商品パフォーマンスを記録します",
      },
      {
        firstText: "クイックビュー",
        secondText:
          "アマゾンを閲覧するときは、すばらしい製品をお見逃しなく。 アマゾンページで、すべての商品の売り手であるかのように、商品の販売データを即座に確認できます",
      },
      {
        firstText: "株式統計",
        secondText:
          "競争者の実際の在庫量を確認して、売上を追跡します。 競争者の「在庫切れ」という状況を確認したら、より多くの売り上げを獲得し、より高い利益を上げましょう。",
      },
    ],
  },
  {
    screenshot: {
      JPEG: JPEG2,
      JPEG2x: JPEG2_2x,
      JPEGMob: JPEG2Mob,
      JPEGMob2x: JPEG2Mob_2x,
      WEBP: WEBP2,
      WEBP2x: WEBP2_2x,
      WEBPMob: WEBP2Mob,
      WEBPMob2x: WEBP2Mob_2x,
    },
    showShadow: true,
    title: "3. 商品を販売するためのキーワード調査ツール",
    list: [
      {
        firstText: "リバースASINルックアップ",
        secondText:
          "ASIN番号を入力すると、商品がランク付けされているキーワードを受け取ります。検索頻度によって商品の需要を見積もったり、既存のキーワードをコピーして競争者に追いつくことができます。",
      },
      {
        firstText: "アマゾンキーワード検索",
        secondText:
          "アマゾン検索結果からのお客様数を最大化するために、商品リストに関連する何千ものキーワードを見つけてください",
      },
      {
        firstText: "キーワードトラッカー",
        secondText:
          "広告キーワードのランク付けを正確に監視することで、広告予算から最大の売り上げを獲得しましょう。",
      },
    ],
  },
  {
    screenshot: {
      JPEG: JPEG3,
      JPEG2x: JPEG3_2x,
      JPEGMob: JPEG3Mob,
      JPEGMob2x: JPEG3Mob_2x,
      WEBP: WEBP3,
      WEBP2x: WEBP3_2x,
      WEBPMob: WEBP3Mob,
      WEBPMob2x: WEBP3Mob_2x,
    },
    showShadow: true,
    title: "4. 売り上げを伸ばすために必要な知識",
    list: [
      {
        firstText: "ステップバイステップコース",
        secondText: "アマゾンで商品を見つけて、販売する方法を学びましょう",
      },
      {
        firstText: "7桁のセラーマスタークラス",
        secondText:
          "成功しているセラーと共に、製品調査と販売のさまざまな側面を詳しく確認してください。",
      },
    ],
  },
]

export default PG_JASB_TOOLS_CONTENT
