import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import JpStandardFormBlockContainer from "../../../../containers/common/forms/JpStandardFormBlockContainer";
import "./index.scss";

const FormWithPicture = (props) => {
  const {
    content: {
      JPEG,
      JPEG2x,
      WEBP,
      WEBP2x,
      title,
      buttonTextFirstState,
      buttonTextSecondState,
    },
    customClass,
    formRef,
    page,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section
      ref={formRef}
      className={getTrueClasses(
        "PgJASB-FormWithPicture",
        !!customClass && customClass,
        isAuth && "PgJASB-FormWithPicture-secondState"
      )}
    >
      <div className="PgJASB-FormWithPicture__img">
        <picture>
          <source type="image/webp" srcSet={`${WEBP} 1x, ${WEBP2x} 2x`} />
          <img srcSet={`${JPEG} 1x, ${JPEG2x} 2x`} alt="" />
        </picture>
      </div>
      <JpStandardFormBlockContainer
        buttonTextFirstState={buttonTextFirstState}
        buttonTextSecondState={buttonTextSecondState}
        page={page}
        customClass={getTrueClasses(
          "PgJASB-bottomForm",
          isAuth && "PgJASB-bottomForm-secondState"
        )}
        title={title}
      />
    </section>
  );
};

FormWithPicture.propTypes = {
  content: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FormWithPicture;
