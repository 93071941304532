import React from "react"
import Jpeg1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1.jpg"
import Jpeg1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@2x.jpg"
import Jpeg1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@mob.jpg"
import Jpeg1Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@mob2x.jpg"
import Webp1 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1.webp"
import Webp1_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@2x.webp"
import Webp1Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@mob.webp"
import Webp1Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-1@mob2x.webp"

import Jpeg2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2.jpg"
import Jpeg2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@2x.jpg"
import Jpeg2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@mob.jpg"
import Jpeg2Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@mob2x.jpg"
import Webp2 from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2.webp"
import Webp2_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@2x.webp"
import Webp2Mob from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@mob.webp"
import Webp2Mob_2x from "../../../../assets/images/raster/pages/jp/amazon-sellers-bundle/KnowHow/webApp-knowHow-2@mob2x.webp"

const PG_JASB_KNOW_HOW_CONTENT = {
  firstScreen: {
    JPEG: Jpeg1,
    JPEG2x: Jpeg1_2x,
    JPEGMob: Jpeg1Mob,
    JPEGMob2x: Jpeg1Mob_2x,
    WEBP: Webp1,
    WEBP2x: Webp1_2x,
    WEBPMob: Webp1Mob,
    WEBPMob2x: Webp1Mob_2x,
  },
  firstListItemsText: [
    "商品を選択する",
    "サプライヤーを見つけて交渉する",
    "販売者のアカウントを作成する",
    "たくさんのことも知る",
  ],

  secondScreen: {
    JPEG: Jpeg2,
    JPEG2x: Jpeg2_2x,
    JPEGMob: Jpeg2Mob,
    JPEGMob2x: Jpeg2Mob_2x,
    WEBP: Webp2,
    WEBP2x: Webp2_2x,
    WEBPMob: Webp2Mob,
    WEBPMob2x: Webp2Mob_2x,
  },
  secondListItemsText: [
    "開始時の予算と好みに基づいて、Amazonでの販売モデルが最も効果的かどうかを確認します",
    "最も収益性の高い商品を選ぶためのヒントと戦略を学ぶ",
    "販売するリストを作成するなど、販売者のタスクの詳細を調べます",
  ],
}
export default PG_JASB_KNOW_HOW_CONTENT
