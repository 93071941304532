import React from "react";
import PG_JASB_KNOW_HOW_CONTENT from "./index.content";
import ScreenshotWithWrapper from "../../../common/UI/ScreenshotWithWrapper";
import "./index.scss";

const KnowHow = () => {
  const { firstScreen, firstListItemsText, secondScreen, secondListItemsText } =
    PG_JASB_KNOW_HOW_CONTENT;

  return (
    <section className="PgJASB-KnowHow">
      <div className="container">
        <h2 className="PgJASB-KnowHow__title">アマゾンで販売する方法</h2>
        <div className="PgJASB-KnowHow-row">
          <div className="PgJASB-KnowHow-block PgJASB-KnowHow-block_one">
            <div className="PgJASB-KnowHow-info">
              <div className="PgJASB-KnowHow-info__title">やり方を学ぶ：</div>
              {firstListItemsText.map((text) => (
                <div key={text} className="PgJASB-KnowHow-info__item">
                  {text}
                </div>
              ))}
              <div className="PgJASB-KnowHow-info__text">
                ステップバイステップコースの<b>23本の教育ビデオ</b>で
              </div>
            </div>
            <ScreenshotWithWrapper
              classes="PgJASB-KnowHow__img"
              screenshot={firstScreen}
              from={768}
              to={767}
            />
          </div>
          <div className="PgJASB-KnowHow-block PgJASB-KnowHow-block_two">
            <div className="PgJASB-KnowHow-info">
              {secondListItemsText.map((text, i) => (
                <div key={i} className="PgJASB-KnowHow-info__item">
                  {text}
                </div>
              ))}
              <div className="PgJASB-KnowHow-info__text">
                <b>7桁のセラー</b>からの<b>34のマスタークラス</b>で！
              </div>
            </div>
            <ScreenshotWithWrapper
              classes="PgJASB-KnowHow__img"
              screenshot={secondScreen}
              from={768}
              to={767}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default KnowHow;
