import React from "react";
import PropTypes from "prop-types";

const HomeIntroScreen = ({ customClass, pictureScreen }) => {
  const {
    PNGHomeIntro,
    PNGHomeIntro_2x,
    PNGHomeIntro991,
    PNGHomeIntro991_2x,
    PNGHomeIntro768,
    PNGHomeIntro768_2x,
    PNGHomeIntroMob,
    PNGHomeIntroMob_2x,
    WEBPHomeIntro,
    WEBPHomeIntro_2x,
    WEBPHomeIntro991,
    WEBPHomeIntro991_2x,
    WEBPHomeIntro768,
    WEBPHomeIntro768_2x,
    WEBPHomeIntroMob,
    WEBPHomeIntroMob_2x,
  } = pictureScreen;

  return (
    <div className={customClass}>
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1200px)"
          srcSet={`${WEBPHomeIntro}, ${WEBPHomeIntro_2x} 2x`}
        />
        <source
          media="(min-width: 1200px)"
          srcSet={`${PNGHomeIntro}, ${PNGHomeIntro_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${WEBPHomeIntro991} 1x, ${WEBPHomeIntro991_2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${PNGHomeIntro991} 1x, ${PNGHomeIntro991_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${WEBPHomeIntro768} 1x, ${WEBPHomeIntro768_2x} 2x`}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={`${PNGHomeIntro768} 1x, ${PNGHomeIntro768_2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBPHomeIntroMob} 1x, ${WEBPHomeIntroMob_2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PNGHomeIntroMob} 1x, ${PNGHomeIntroMob_2x} 2x`}
        />
        <img src={PNGHomeIntro} alt="image" loading="lazy" />
      </picture>
    </div>
  );
};

HomeIntroScreen.propTypes = {
  customClass: PropTypes.string,
  pictureScreen: PropTypes.object,
};

export default HomeIntroScreen;
