import React from "react";
import "./index.scss";

const LastSection = () => (
  <section className="PgJASB-LastSection">
    <div className="container">
      <h2 className="PgJASB-LastSection__title">
        ご覧のとおり、AMZScoutは完全なAmazon販売ツールスイートを提供しています
      </h2>
      <div className="PgJASB-LastSection__subtitle">
        分析、リストの最適化、マーケティング、競争者の分析のための製品ファインダーソフトウェアを使用
      </div>
      <div className="PgJASB-LastSection__text">
        販売するのに最適なニッチを見つけて、製品に競争力のある価格を選択するために、マーケットプレイススキャナーとアナライザーを備えたAmazonビジネス向けのこのツールキットを試してください。
      </div>
    </div>
  </section>
);

export default LastSection;
