import React from "react";

const SellSectionScreenshot = ({ screenshot, classes, index }) => {
  const {
    JPEG,
    JPEG2x,
    JPEGTab,
    JPEGTab2x,
    JPEGMob,
    JPEGMob2x,
    WEBP,
    WEBP2x,
    WEBPTab,
    WEBPTab2x,
    WEBPMob,
    WEBPMob2x,
  } = screenshot;

  return (
    <div className={classes}>
      <picture>
        <source
          type="image/webp"
          media={index === 0 ? "(min-width: 1366px)" : "(min-width: 992px)"}
          srcSet={`${WEBP} 1x, ${WEBP2x} 2x`}
        />
        <source
          media={index === 0 ? "(min-width: 1366px)" : "(min-width: 992px)"}
          srcSet={`${JPEG} 1x, ${JPEG2x} 2x`}
        />
        <source
          type="image/webp"
          media={`(min-width: 768px) and ${
            index === 0 ? "(max-width: 1365px)" : "(max-width: 991px)"
          }`}
          srcSet={`${WEBPTab} 1x, ${WEBPTab2x} 2x`}
        />
        <source
          media={`(min-width: 768px) and ${
            index === 0 ? "(max-width: 1365px)" : "(max-width: 991px)"
          }`}
          srcSet={`${JPEGTab} 1x, ${JPEGTab2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WEBPMob} 1x, ${WEBPMob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${JPEGMob} 1x, ${JPEGMob2x} 2x`}
        />
        <img src={JPEG} alt="img" loading="lazy" />
      </picture>
    </div>
  );
};

export default SellSectionScreenshot;
